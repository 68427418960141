import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Typography, Grid, TextField, Switch, FormControlLabel, Button, Snackbar, Alert, CircularProgress, Box } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import axios from 'axios';
import { createClient } from '@supabase/supabase-js';
import { useLocation } from 'react-router-dom';
import { isValidEmail } from '../utils/validation'; // Assume this utility function exists
import { useTheme } from '@mui/material/styles';

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY;

const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

function Settings() {
  const { currentUser } = useAuth();
  const [profileData, setProfileData] = useState({
    title: '',
    department: '',
    rolesResponsibilities: '',
    active: false,
    managerEmail: '',
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCalendarConnected, setIsCalendarConnected] = useState(false);
  const [isCalendarLoading, setIsCalendarLoading] = useState(false);
  const location = useLocation();
  const [calendarId, setCalendarId] = useState(null);
  const [emailError, setEmailError] = useState('');
  const theme = useTheme();

  const fetchUserDetails = useCallback(async (email) => {
    if (!email) return;
    
    setIsLoading(true);
    try {
      const cleanedEmail = email.trim().replace(/^["']|["']$/g, '');
      
      // First, fetch the user's details
      const { data: userData, error: userError } = await supabase
        .from('people')
        .select('*')
        .eq('email', cleanedEmail)
        .single();

      if (userError) throw userError;

      if (userData) {
        let rolesResponsibilities = '';
        if (typeof userData.roles_responsibilities === 'object' && userData.roles_responsibilities !== null) {
          if (userData.roles_responsibilities.responsibilities) {
            if (Array.isArray(userData.roles_responsibilities.responsibilities)) {
              rolesResponsibilities = userData.roles_responsibilities.responsibilities.join('\n');
            } else {
              rolesResponsibilities = JSON.stringify(userData.roles_responsibilities.responsibilities, null, 2);
            }
          } else {
            rolesResponsibilities = JSON.stringify(userData.roles_responsibilities, null, 2);
          }
        } else if (typeof userData.roles_responsibilities === 'string') {
          rolesResponsibilities = userData.roles_responsibilities;
        }

        console.log('Extracted roles and responsibilities:', rolesResponsibilities);

        let managerEmail = '';
        if (userData.reports_to && Array.isArray(userData.reports_to) && userData.reports_to.length > 0) {
          try {
            // Fetch the manager's email using the first UUID in the reports_to array
            const { data: managerData, error: managerError } = await supabase
              .from('people')
              .select('email')
              .eq('person_uuid', userData.reports_to[0])
              .single();

            if (managerError) {
              console.error('Error fetching manager details:', managerError);
            } else if (managerData && managerData.email) {
              managerEmail = managerData.email;
            } else {
              console.warn('Manager data not found or email is missing');
            }
          } catch (managerFetchError) {
            console.error('Failed to fetch manager details:', managerFetchError);
          }
        } else {
          console.log('No manager assigned or reports_to is not properly formatted');
        }

        setProfileData({
          title: userData.title || '',
          department: userData.department || '',
          rolesResponsibilities: rolesResponsibilities,
          active: userData.active || false,
          managerEmail: managerEmail,
        });
        setCalendarId(userData.calendar_id);
        setIsCalendarConnected(!!userData.calendar_id);
      } else {
        setError('User not found in the database. Please contact support.');
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
      setError('Failed to fetch user details. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (currentUser?.email) {
      fetchUserDetails(currentUser.email);
    }
  }, [currentUser, fetchUserDetails]);

  useEffect(() => {
    if (location.state?.calendarConnected) {
      setIsCalendarConnected(true);
    }
    if (location.state?.error) {
      setError(location.state.error);
    }
  }, [location]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfileData(prevData => ({
      ...prevData,
      [name]: value,
    }));

    // Validate email when the managerEmail field changes
    if (name === 'managerEmail') {
      if (value && !isValidEmail(value)) {
        setEmailError('Please enter a valid email address');
      } else {
        setEmailError('');
      }
    }
  };

  const handleActiveToggle = () => {
    setProfileData(prevData => ({ ...prevData, active: !prevData.active }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (profileData.managerEmail && !isValidEmail(profileData.managerEmail)) {
      setEmailError('Please enter a valid email address');
      return;
    }
    try {
      const submitData = {
        email: currentUser.email,
        title: profileData.title,
        department: profileData.department,
        active: profileData.active,
        manager_email: profileData.managerEmail,
        // Keep roles_responsibilities in the submitData, but don't process it
        roles_responsibilities: profileData.rolesResponsibilities,
      };

      console.log('Submitting data:', submitData);

      const response = await axios.post(
        "https://n8n.rappo.ai/webhook/b4bdfffe-f32a-448f-8100-92183f23e064", 
        submitData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        alert('Profile updated successfully!');
        await fetchUserDetails(currentUser.email);
      } else {
        throw new Error('Failed to update profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile: ' + (error.response?.data?.message || error.message));
    }
  };

  const handleCalendarConnection = async () => {
    setIsCalendarLoading(true);
    if (isCalendarConnected) {
      // Disconnect calendar using n8n scenario
      try {
        // Fetch the calendar ID from Supabase
        const { data, error } = await supabase
          .from('people')
          .select('calendar_id')
          .eq('email', currentUser.email)
          .single();

        if (error) throw error;

        if (!data.calendar_id) {
          throw new Error('No connected calendar found');
        }

        const response = await axios.post(
          "https://n8n.rappo.ai/webhook/79f21c72-05f1-4bd8-9e72-0faec13b9174",
          {
            email: currentUser.email,
            calendarId: data.calendar_id
          }
        );

        if (response.status === 200) {
          setIsCalendarConnected(false);
          setCalendarId(null);
          // Optionally, you can show a success message here
        } else {
          throw new Error('Failed to disconnect calendar');
        }
      } catch (error) {
        console.error('Error disconnecting calendar:', error);
        setError('Failed to disconnect calendar. Please try again.');
      } finally {
        setIsCalendarLoading(false);
      }
    } else {
      // Connect calendar (keep this part as is)
      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${encodeURIComponent(window.location.origin + '/auth/callback')}&response_type=code&scope=https://www.googleapis.com/auth/calendar.readonly&access_type=offline&prompt=consent&login_hint=${encodeURIComponent(currentUser.email)}`;
      window.location.href = authUrl;
    }
  };

  return (
    <Box sx={{ maxWidth: 800, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        Profile Settings
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Grid container spacing={4} direction="column">
          <Grid item xs={12}>
            <Box sx={{ backgroundColor: theme.palette.background.paper, p: 3, borderRadius: 1, border: '1px solid', borderColor: theme.palette.divider }}>
              <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
                Personal Information
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Job Title"
                      name="title"
                      value={profileData.title}
                      onChange={handleInputChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Department"
                      name="department"
                      value={profileData.department}
                      onChange={handleInputChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Manager's Email"
                      name="managerEmail"
                      value={profileData.managerEmail}
                      onChange={handleInputChange}
                      variant="outlined"
                      error={!!emailError}
                      helperText={emailError}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Roles and Responsibilities"
                      name="rolesResponsibilities"
                      value={profileData.rolesResponsibilities}
                      onChange={handleInputChange}
                      multiline
                      rows={4}
                      variant="outlined"
                      disabled={true} // Disable the field
                      helperText="This field is currently disabled" // Optional: Add a helper text
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ backgroundColor: 'action.hover', p: 2, borderRadius: 1 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={profileData.active}
                            onChange={handleActiveToggle}
                            color="primary"
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {profileData.active ? "Profile Active" : "Profile Inactive"}
                          </Typography>
                        }
                      />
                      <Typography variant="caption" display="block" sx={{ mt: 1, color: 'text.secondary' }}>
                        Toggle this switch to activate or deactivate your profile.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary">
                      Save Changes
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ backgroundColor: theme.palette.background.paper, p: 3, borderRadius: 1, border: '1px solid', borderColor: theme.palette.divider }}>
              <Typography variant="h6" gutterBottom sx={{ mb: 1 }}>
                Calendar Connection
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph sx={{ mb: 2 }}>
                Connect your Google Calendar to enable Zeus to join your upcoming meetings.
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <CalendarMonthIcon sx={{ mr: 1, color: 'primary.main' }} />
                <Typography variant="body1">
                  {isCalendarConnected 
                    ? `Google Calendar Connected (${currentUser.email})` 
                    : "Connect Google Calendar"}
                </Typography>
              </Box>
              {isCalendarLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Button
                  onClick={handleCalendarConnection}
                  variant="outlined"
                  color={isCalendarConnected ? "error" : "primary"}
                  startIcon={<CalendarMonthIcon />}
                  sx={{ mb: 2 }}
                >
                  {isCalendarConnected ? "Disconnect Calendar" : "Connect Calendar"}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Settings;