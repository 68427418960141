import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from '../contexts/AuthContext';
import { Box, Button, Container, Typography, CircularProgress } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import axios from 'axios';

function Login() {
  const navigate = useNavigate();
  const { login, currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      navigate('/home');
    }
  }, [currentUser, navigate]);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setIsLoading(true);
      console.log(tokenResponse);
      try {
        const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        });
        
        const userData = {
          ...userInfo.data,
          access_token: tokenResponse.access_token,
          email: userInfo.data.email,
        };
        
        login(userData);
        navigate('/home');
      } catch (error) {
        console.error('Error fetching user info:', error);
        setIsLoading(false);
      }
    },
    onError: () => {
      console.log('Login Failed');
      setIsLoading(false);
    },
  });

  if (isLoading) {
    return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
          <Typography variant="h6" style={{ marginTop: 20 }}>
            Logging you in...
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in to access the dashboard
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          startIcon={<GoogleIcon />}
          onClick={() => googleLogin()}
          sx={{ mt: 3, mb: 2 }}
        >
          Sign in with Google
        </Button>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary" align="center">
            © Stoa
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
