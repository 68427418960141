import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import OnboardingCheck from './OnboardingCheck';

const PrivateRoute = () => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return null; // or a loading spinner
  }

  return currentUser ? (
    <OnboardingCheck>
      <Outlet />
    </OnboardingCheck>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;
