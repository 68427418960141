import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { createClient } from '@supabase/supabase-js';
import { CircularProgress, Typography, Box } from '@mui/material';

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY;

const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

function AuthCallback() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [status, setStatus] = useState('Processing...');
  const callbackExecuted = useRef(false);

  const handleCallback = useCallback(async () => {
    if (callbackExecuted.current || !currentUser) return;
    callbackExecuted.current = true;

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (!code) {
      setStatus('No authorization code found');
      setTimeout(() => navigate('/settings'), 2000);
      return;
    }

    try {
      setStatus('Connecting to Google Calendar...');
      const response = await axios.post('https://n8n.rappo.ai/webhook/auth/callback', {
        code,
        user: currentUser
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      console.log('Response from n8n:', response.data);

      if (response.data.success) {
        setStatus('Updating user profile...');
        const { error } = await supabase
          .from('people')
          .update({ calendar_refresh_token: response.data.refresh_token })
          .eq('email', currentUser.email);

        if (error) throw error;

        setStatus('Calendar connected successfully!');
        setTimeout(() => navigate('/settings', { state: { calendarConnected: true } }), 2000);
      } else {
        throw new Error('Failed to connect calendar');
      }
    } catch (error) {
      console.error('Error connecting Google Calendar:', error);
      console.error('Error details:', error.response ? error.response.data : 'No response data');
      console.error('Status code:', error.response ? error.response.status : 'No status code');
      setStatus(`Error: ${error.message || 'Failed to connect calendar'}. Status: ${error.response ? error.response.status : 'Unknown'}`);
      setTimeout(() => navigate('/settings', { 
        state: { 
          calendarConnected: false, 
          error: `${error.message}. Status: ${error.response ? error.response.status : 'Unknown'}` 
        } 
      }), 2000);
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    handleCallback();
  }, [handleCallback]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <CircularProgress />
      <Typography variant="h6" style={{ marginTop: 20 }}>{status}</Typography>
    </Box>
  );
}

export default AuthCallback;