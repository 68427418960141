import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, IconButton, Collapse, Pagination, MenuItem, TextField, Card, CardContent, Divider, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { createClient } from '@supabase/supabase-js';

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY;

const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

// Styled components for the table
const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

function Row(props) {
  const { meeting } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>{new Date(meeting.start_time).toLocaleDateString()}</TableCell>
        <TableCell>{meeting.title}</TableCell>
        <TableCell>{meeting.classifier?.type || 'N/A'}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom component="div" color="primary">
                    Meeting Details
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                  <Typography variant="body1" paragraph>
                    <strong>Date:</strong> {new Date(meeting.start_time).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <strong>Time:</strong> {new Date(meeting.start_time).toLocaleTimeString()} - {new Date(meeting.end_time).toLocaleTimeString()}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <strong>Type:</strong> {meeting.classifier?.type || 'N/A'}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    <strong>Summary:</strong>
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1, backgroundColor: 'rgba(0, 0, 0, 0.03)', p: 2, borderRadius: 1 }}>
                    {meeting.summary || 'No summary available'}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Home() {
  const { currentUser } = useAuth();
  const [meetings, setMeetings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchMeetings = async () => {
      setIsLoading(true);
      try {
        const { data: userData, error: userError } = await supabase
          .from('people')
          .select('person_uuid')
          .eq('email', currentUser.email)
          .single();

        if (userError) throw userError;
        if (!userData) throw new Error('User not found');

        const { data: meetingsData, error: meetingsError, count } = await supabase
          .from('meetings')
          .select('*', { count: 'exact' })
          .contains('participants', [userData.person_uuid])
          .order('start_time', { ascending: false })
          .range((page - 1) * rowsPerPage, page * rowsPerPage - 1);

        if (meetingsError) throw meetingsError;

        setMeetings(meetingsData);
        setTotalPages(Math.ceil(count / rowsPerPage));
      } catch (error) {
        console.error('Error fetching meetings:', error);
        setError('Failed to fetch meetings. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    if (currentUser?.email) {
      fetchMeetings();
    }
  }, [currentUser, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Welcome, {currentUser?.firstName || 'User'}!
      </Typography>
      <Typography variant="h5" gutterBottom>
        Your Recent Meetings
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <StyledTableHead>
                <TableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Title</StyledTableCell>
                  <StyledTableCell>Classifier Type</StyledTableCell>
                  <StyledTableCell />
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {meetings.map((meeting) => (
                  <Row key={meeting.meeting_uuid} meeting={meeting} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" sx={{ mr: 2 }}>
                Rows per page:
              </Typography>
              <TextField
                select
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
                variant="outlined"
                size="small"
                sx={{ width: 80 }}
              >
                {[10, 25, 50, 100].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default Home;
