import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { createClient } from '@supabase/supabase-js';
import { CircularProgress, Typography, Box, Button } from '@mui/material';
import Header from './Header';

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY;

const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

function OnboardingCheck({ children }) {
  const [status, setStatus] = useState('checking');
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      if (!currentUser?.email) {
        setStatus('no_user');
        if (location.pathname !== '/login') {
          navigate('/login');
        }
        return;
      }

      try {
        const { data, error } = await supabase
          .from('people')
          .select('onboarding_status')
          .eq('email', currentUser.email)
          .single();

        if (error) {
          if (error.code === 'PGRST116') {
            // User not found in the database
            setStatus('not_in_database');
          } else {
            throw error;
          }
        } else {
          const validStatuses = [
            'Partially Onboarded',
            'Fully Onboarded',
            'R&R Pending',
            'Approval Pending'
          ];

          if (validStatuses.includes(data.onboarding_status)) {
            setStatus('onboarded');
          } else {
            setStatus('not_onboarded');
          }
        }
      } catch (error) {
        console.error('Error checking onboarding status:', error);
        setStatus('error');
      }
    };

    checkOnboardingStatus();
  }, [currentUser, navigate, location, logout]);

  const handleRedirectToWaitlist = () => {
    window.location.href = 'https://waitlist.stoa.com';
  };

  const renderContent = () => {
    switch (status) {
      case 'checking':
      case 'no_user':
        return (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="calc(100vh - 64px)">
            <CircularProgress />
            <Typography variant="h6" style={{ marginTop: 20 }}>
              {status === 'checking' ? 'Checking onboarding status...' : 'No user found. Redirecting to login...'}
            </Typography>
          </Box>
        );
      case 'not_in_database':
      case 'not_onboarded':
        return (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="calc(100vh - 64px)">
            <Typography variant="h5" gutterBottom>
              Your account is not fully onboarded yet.
            </Typography>
            <Typography variant="body1" paragraph>
              Please join our waitlist to get early access when ready.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleRedirectToWaitlist}>
              Join Waitlist
            </Button>
          </Box>
        );
      case 'error':
        return (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="calc(100vh - 64px)">
            <Typography variant="h5" gutterBottom>
              An error occurred while checking your onboarding status.
            </Typography>
            <Typography variant="body1" paragraph>
              Please try again later or contact support.
            </Typography>
          </Box>
        );
      default:
        return children;
    }
  };

  return (
    <>
      <Header />
      {renderContent()}
    </>
  );
}

export default OnboardingCheck;
