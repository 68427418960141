import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Login from './pages/Login';
import Home from './pages/Home';
import PrivateRoute from './components/PrivateRoute';
import Settings from './pages/Settings';
import AuthCallback from './components/AuthCallback';
import Layout from './components/Layout';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2D0C0C', // Deep brown
    },
    secondary: {
      main: '#F15A22', // Vibrant orange
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF', // Changed from '#F5F5F5' to white
    },
    text: {
      primary: '#2D0C0C',
      secondary: '#4A4A4A',
    },
    headerText: '#F8F8F8', // A very light grey, almost white
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: { color: '#2D0C0C' },
    h2: { color: '#2D0C0C' },
    h3: { color: '#2D0C0C' },
    h4: { color: '#2D0C0C' },
    h5: { color: '#2D0C0C' },
    h6: { color: '#2D0C0C' },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route element={<PrivateRoute />}>
                <Route path="/home" element={<Layout><Home /></Layout>} />
                <Route path="/settings" element={<Layout><Settings /></Layout>} />
                <Route path="/auth/callback" element={<AuthCallback />} />
              </Route>
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </Router>
        </AuthProvider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
